import "./scss/index.scss";
import * as React from "react";
import { Loader } from "@components/atoms";
import { useAuth, useOrderDetails } from "@saleor/sdk";
import Page from "./Page";

var View = function View(_ref) {
  var token = _ref.match.params.token;

  var _useOrderDetails = useOrderDetails({
    token: token
  } // { fetchPolicy: "cache-and-network" }
  ),
      order = _useOrderDetails.data,
      loading = _useOrderDetails.loading;

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var guest = !user;

  var handleDownloadInvoice = function handleDownloadInvoice() {
    var _order$invoices;

    if (order && "invoices" in order && ((_order$invoices = order.invoices) === null || _order$invoices === void 0 ? void 0 : _order$invoices.length) > 0) {
      // Always download latest invoice
      var invoice = order.invoices.reduce(function (a, b) {
        return new Date(a.createdAt) > new Date(b.createdAt) ? a : b;
      });

      if (invoice) {
        window.open(invoice.url, "_blank");
      }
    }
  };

  if (loading) {
    return React.createElement(Loader, null);
  }

  return React.createElement("div", {
    className: "order-details container"
  }, React.createElement(Page, {
    guest: guest,
    order: order,
    downloadInvoice: handleDownloadInvoice
  }));
};

export default View;