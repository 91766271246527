import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import classNames from "classnames";
import React from "react";
import Media from "react-media";
import { smallScreen } from "../../globalStyles/scss/variables.scss";
import { Breadcrumbs, ProductDescription } from "../../components";
import { generateCategoryUrl, generateProductUrl } from "../../core/utils";
import GalleryCarousel from "./GalleryCarousel";
import OtherProducts from "./Other";
import { ProductDescription as NewProductDescription } from "../../@next/components/molecules";
import { ProductGallery } from "../../@next/components/organisms";
import { structuredData } from "../../core/SEO/Product/structuredData";

var populateBreadcrumbs = function populateBreadcrumbs(product) {
  if (product.category) return [{
    link: generateCategoryUrl(product.category.id, product.category.name),
    value: product.category.name
  }, {
    link: generateProductUrl(product.id, product.name),
    value: product.name
  }];else {
    return [{
      link: generateProductUrl(product.id, product.name),
      value: product.name
    }];
  }
};

var Page = function Page(_ref) {
  var add = _ref.add,
      product = _ref.product,
      items = _ref.items,
      queryAttributes = _ref.queryAttributes,
      onAttributeChangeHandler = _ref.onAttributeChangeHandler;
  var productGallery = React.useRef();

  var _React$useState = React.useState(""),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      variantId = _React$useState2[0],
      setVariantId = _React$useState2[1];

  var getImages = function getImages() {
    if (product.variants && variantId) {
      var variant = product.variants.filter(function (variant) {
        return variant.id === variantId;
      }).pop();

      if (variant.images.length > 0) {
        return variant.images;
      }

      return product.images;
    }

    return product.images;
  };

  var productDescription = React.createElement(ProductDescription, {
    items: items,
    productId: product.id,
    name: product.name,
    productVariants: product.variants,
    pricing: product.pricing,
    queryAttributes: queryAttributes,
    addToCart: add,
    setVariantId: setVariantId,
    onAttributeChangeHandler: onAttributeChangeHandler
  });
  return React.createElement("div", {
    className: "product-page"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement(Breadcrumbs, {
    breadcrumbs: populateBreadcrumbs(product)
  })), React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "product-page__product"
  }, React.createElement("script", {
    className: "structured-data-list",
    type: "application/ld+json"
  }, structuredData(product)), React.createElement(Media, {
    query: {
      maxWidth: smallScreen
    }
  }, function (matches) {
    return matches ? React.createElement(React.Fragment, null, React.createElement(GalleryCarousel, {
      images: getImages()
    }), React.createElement("div", {
      className: "product-page__product__info"
    }, productDescription)) : React.createElement(React.Fragment, null, React.createElement("div", {
      className: "product-page__product__gallery",
      ref: productGallery
    }, React.createElement(ProductGallery, {
      images: getImages()
    })), React.createElement("div", {
      className: "product-page__product__info"
    }, React.createElement("div", {
      className: classNames("product-page__product__info--fixed")
    }, productDescription)));
  }))), React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "product-page__product__description"
  }, React.createElement(NewProductDescription, {
    descriptionJson: product.descriptionJson,
    attributes: product.attributes
  }))), product.category && React.createElement(OtherProducts, {
    products: product.category.products.edges
  }));
};

export default Page;