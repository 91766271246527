import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import "./scss/index.scss";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { TypedLatestProductsQuery } from "@temp/components/ProductsFeatured/queries";
import { Button, Loader, ProductListItem } from "../../components";
import { generateProductUrl } from "../../core/utils";
import { structuredData } from "../../core/SEO/Homepage/structuredData";
import { maybe } from "@temp/@next/utils/misc";
import { PointComp } from "@temp/components/Breadcrumbs";

var Page = function Page(_ref) {
  var loading = _ref.loading,
      categories = _ref.categories,
      backgroundImage = _ref.backgroundImage,
      shop = _ref.shop;

  var categoriesExist = function categoriesExist() {
    return categories && categories.edges && categories.edges.length > 0;
  };

  var intl = useIntl();
  return React.createElement(React.Fragment, null, React.createElement("script", {
    className: "structured-data-list",
    type: "application/ld+json"
  }, structuredData(shop)), React.createElement(PointComp, {
    showExpire: false,
    showName: true,
    need_refetch: true
  }), React.createElement(TypedLatestProductsQuery, {
    displayError: false
  }, function (_ref2) {
    var data = _ref2.data,
        loading = _ref2.loading,
        loadMore = _ref2.loadMore;
    var products = maybe(function () {
      return data.products.edges;
    }, []);

    var onloadMore = function onloadMore() {
      return loadMore(function (prev, next) {
        return _objectSpread({}, prev, {
          products: _objectSpread({}, prev.products, {
            edges: [].concat(_toConsumableArray(prev.products.edges), _toConsumableArray(next.products.edges)),
            pageInfo: next.products.pageInfo
          })
        });
      }, {
        after: data.products.pageInfo.endCursor
      });
    }; // console.log(data)


    if (products.length) {
      return React.createElement("div", {
        className: "products-featured"
      }, React.createElement("div", {
        className: "container"
      }, React.createElement("h3", null), React.createElement("div", {
        className: "landing_items"
      }, products.map(function (_ref3) {
        var product = _ref3.node;
        return React.createElement(Link, {
          to: generateProductUrl(product.id, product.name),
          key: product.id
        }, React.createElement(ProductListItem, {
          product: product
        }));
      }))), loading ? React.createElement(Loader, null) : data.products.pageInfo.hasNextPage && React.createElement(Button, {
        style: {
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 40
        },
        testingContext: "loadMoreProductsButton",
        color: "secondary",
        onClick: onloadMore
      }, React.createElement(FormattedMessage, {
        id: "views.Home.Page.2783099424",
        defaultMessage: "More +"
      })));
    }

    return null;
  }));
};

export default Page;