import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import "./scss/index.scss";
import { useCart } from "@saleor/sdk";
import { isEmpty } from "lodash";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MetaWrapper, NotFound, OfflinePlaceholder } from "../../components";
import NetworkStatus from "../../components/NetworkStatus";
import { getGraphqlIdFromDBId, maybe } from "../../core/utils";
import Page from "./Page";
import { TypedProductDetailsQuery } from "./queries";
import { useFunc, GET_ME } from "@temp/@next/hooks/useQuery";
import swal from "sweetalert";

var canDisplay = function canDisplay(product) {
  return maybe(function () {
    return !!product.descriptionJson && !!product.name && !!product.pricing && !!product.variants;
  });
};

var extractMeta = function extractMeta(product) {
  return {
    custom: [{
      content: product.pricing.priceRange.start.gross.amount.toString(),
      property: "product:price:amount"
    }, {
      content: product.pricing.priceRange.start.gross.currency,
      property: "product:price:currency"
    }, {
      content: product.isAvailable ? "in stock" : "out off stock",
      property: "product:isAvailable"
    }, {
      content: maybe(function () {
        return product.category.name;
      }),
      property: "product:category"
    }],
    description: product.seoDescription || product.descriptionJson,
    image: maybe(function () {
      return product.thumbnail.url;
    }, null),
    title: product.seoTitle || product.name,
    type: "product.item",
    url: window.location.href
  };
};

var PageWithQueryAttributes = function PageWithQueryAttributes(props) {
  var product = props.product;
  var history = useHistory();
  var search = history.location.search;
  var searchQueryAttributes = queryString.parse(search);

  var onAttributeChangeHandler = function onAttributeChangeHandler(slug, value) {
    history.replace(queryString.stringifyUrl({
      query: _defineProperty({}, slug, value),
      url: "".concat(history.location.pathname).concat(history.location.search)
    }, {
      skipEmptyString: true
    }));
  };

  var _useState = useState({}),
      _useState2 = _slicedToArray(_useState, 2),
      queryAttributes = _useState2[0],
      setQueryAttributes = _useState2[1];

  useEffect(function () {
    if (!isEmpty(searchQueryAttributes)) {
      var _queryAttributes = {};
      product.variants.forEach(function (_ref) {
        var attributes = _ref.attributes;
        attributes.forEach(function (_ref2) {
          var attribute = _ref2.attribute,
              values = _ref2.values;
          var selectedAttributeValue = searchQueryAttributes[attribute.slug];

          if (selectedAttributeValue && values[0].value === selectedAttributeValue) {
            if (isEmpty(_queryAttributes) || !attributes.filter(function (_ref3) {
              var id = _ref3.attribute.id,
                  values = _ref3.values;
              return _queryAttributes[id] && _queryAttributes[id] !== values[0].value;
            }).length) {
              _queryAttributes[attribute.id] = selectedAttributeValue;
            }
          }
        });
      });
      setQueryAttributes(_queryAttributes);
    }
  }, [product.variants.length]);
  useEffect(function () {
    history.replace(history.location.pathname);
  }, [queryAttributes]);
  return React.createElement(Page, _extends({}, props, {
    queryAttributes: queryAttributes,
    onAttributeChangeHandler: onAttributeChangeHandler
  }));
};

var View = function View(_ref4) {
  var match = _ref4.match;

  var _useCart = useCart(),
      addItem = _useCart.addItem,
      items = _useCart.items;

  var _useFunc = useFunc(GET_ME),
      _useFunc2 = _slicedToArray(_useFunc, 1),
      _useFunc2$ = _useFunc2[0],
      data = _useFunc2$.data,
      fetch = _useFunc2$.fetch;

  useEffect(function () {
    fetch();
  }, []);
  var points = 0; // console.log(totalPrice)

  try {
    points = parseInt(data.data.me.metadata.filter(function (item) {
      return item.key === "points";
    })[0].value);
  } catch (e) {
    points = 0;
  }

  return React.createElement(TypedProductDetailsQuery, {
    loaderFull: true,
    variables: {
      id: getGraphqlIdFromDBId(match.params.id, "Product")
    },
    errorPolicy: "all",
    key: match.params.id
  }, function (_ref5) {
    var data = _ref5.data;
    return React.createElement(NetworkStatus, null, function (isOnline) {
      var product = data.product;

      if (canDisplay(product)) {
        return React.createElement(MetaWrapper, {
          meta: extractMeta(product)
        }, React.createElement(PageWithQueryAttributes, {
          product: product,
          add: function add(variantId, quantity) {
            var amount = product.variants.find(function (item) {
              return item.id === variantId;
            }).pricing.price.net.amount; // let amount =  items.find( (item)=> item.id=== variantId).totalPrice.net.amount

            if (amount > points) swal("Sorry, you do not have enough point to add this item");else addItem(variantId, quantity);
          },
          items: items
        }));
      }

      if (product === null) {
        return React.createElement(NotFound, null);
      }

      if (!isOnline) {
        return React.createElement(OfflinePlaceholder, null);
      }
    });
  });
};

export default View;